<template>
  <app-dropdown-menu v-if="me" :items="menuItems" position="right-bottom">
    <app-user-avatar
      :avatar-url="me.avatarUrl"
      class="!h-10 !w-10"
      :name="me.full_name"
      :size="100"
    />
  </app-dropdown-menu>
</template>

<script lang="ts" setup>
import type { DropdownMenuItem } from "@pollen/ui-library/client/shared/dropdown/dropdown.model";

const { me, logout } = useCurrentUser();

const menuItems: DropdownMenuItem[] = [
  {
    label: "Me déconnecter",
    icon: "ph:sign-out",
    listeners: {
      click: logout,
    },
  },
];
</script>
